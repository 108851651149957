<template>
  <div>
    <v-row>
      <v-col class="text-h6" cols="6" id="assessment-skills">
        <v-row>
          <v-col>
            <span class="text-h6"> Skills: </span>
            <ol>
              <li class="text-body-1" v-for="t in testSkills" :key="t">
                {{ t }}
              </li>
            </ol>
          </v-col>
        </v-row>
        <v-row class="text-body-1">
          <v-col>
            <span v-if="minScore > 0" class="text-h6 mt-4"> Settings: </span>
            <span v-on:click="showMinScoreInfo = true" style="cursor: pointer"
              >Minimum Score
              <v-icon small class="ml-1" color="grey lighten-1">{{
                mdiInformationOutline
              }}</v-icon>
              : {{ minScore }}</span
            >
            <v-btn icon @click="showMinScoreInput = true">
              <v-icon>{{ mdiPencil }}</v-icon>
            </v-btn>
            <v-dialog v-model="showMinScoreInput" persistent max-width="400px">
              <v-card>
                <v-card-title>
                  <span class="text-h6">Change Minimum Score</span>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="minScore"
                    label="New Minimum Score"
                    type="number"
                    :rules="[minScoreRule]"
                  ></v-text-field>
                  <v-checkbox
                    v-model="sendEmail"
                    label="Send Email?"
                  ></v-checkbox>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="showMinScoreInput = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="changeMinScore"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showMinScoreInfo" click-outside-to-close>
              <v-card>
                <v-card-title>
                  <span class="text-h6">Minimum Score Information</span>
                </v-card-title>
                <v-card-text>
                  Candidates with a score less than {{ minScore }} will be
                  automatically rejected.
                  <div v-if="sendEmail">
                    And an automatic rejection email will be send.
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="showMinScoreInfo = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="text-body-1">
          <v-col>
            <div
              class="text-h6"
              v-on:click="showInfo = true"
              style="cursor: pointer"
            >
              Test Timings:
              <v-icon small class="ml-1" color="grey lighten-1">{{
                mdiInformationOutline
              }}</v-icon>
            </div>
            <v-dialog v-model="showInfo" click-outside-to-close>
              <v-card>
                <v-card-title>
                  <span class="text-h6">Test Start Information</span>
                </v-card-title>
                <v-card-text>
                  Candidates will be able to start the test from the start time
                  onwards. And test can't be started after the end time.
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="showInfo = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-row>
              <v-col>
                Test Start Time : {{ formatDate(testStartAt) }}
                <v-btn icon @click="openStartTime">
                  <v-icon>{{ mdiPencil }}</v-icon>
                </v-btn>
                <time-select
                  ref="startTime"
                  v-model="testStartAt"
                ></time-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Test End Time:
                {{ formatDate(testEndAt) }}
                <v-btn icon @click="openEndTime">
                  <v-icon>{{ mdiPencil }}</v-icon>
                </v-btn>
                <time-select ref="endTime" v-model="testEndAt"></time-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col id="assessment-anticheats">
        <div class="text-h6">Anti-cheating Settings</div>
        <ul class="text-body-1">
          <li v-for="t in antiCheats" :key="t">
            {{ t }}
          </li>
        </ul>
        <div class="test-deadline pt-5">
          <div
            class="text-h6"
            v-on:click="showDeadlineInfo = true"
            style="cursor: pointer"
          >
            Test Deadline:
            <v-icon small class="ml-1" color="grey lighten-1">{{
              mdiInformationOutline
            }}</v-icon>
          </div>
          <v-dialog v-model="showDeadlineInfo" click-outside-to-close>
            <v-card>
              <v-card-title>
                <span class="text-h6">Test Deadline Information</span>
              </v-card-title>
              <v-card-text>
                Candidates will have to start the test within this deadline
                after receiving the test invite.
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="showDeadlineInfo = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div v-if="!editDeadline">
            <span v-if="hasDeadline">
              <span
                class="text-body-1"
                v-for="unit in timeUnits"
                :key="unit.label"
              >
                {{ unit.value }} {{ unit.label }}
              </span>
            </span>
            <span v-else> None </span>
            <v-btn icon @click="editDeadline = true">
              <v-icon>{{ mdiPencil }}</v-icon>
            </v-btn>
          </div>
          <div v-if="editDeadline">
            <v-row align="center" no-gutters>
              <v-col cols="3" v-for="unit in timeUnits" :key="unit.label">
                <v-text-field
                  v-model="unit.value"
                  :label="unit.label"
                  type="number"
                  :placeholder="`Enter ${unit.label}`"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn @click="updateDeadline"
                  ><v-icon>{{ mdiCheck }}</v-icon></v-btn
                >
              </v-col>
              <v-col>
                <v-btn @click="removeDeadline"
                  ><v-icon>{{ mdiDelete }}</v-icon></v-btn
                >
              </v-col>
              <v-col>
                <v-btn @click="cancelDeadlineEdit"
                  ><v-icon>{{ mdiClose }}</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="testByUrlEnabled">
      <v-col cols="12" class="d-flex justify-space-between">
        <div>
          <span class="text-h6"> Test Invite URL: </span>
          <a class="text-body-1">
            {{ testUrl }}
          </a>
        </div>
        <v-btn large text outlined @click="copyTestUrlToClipboard">
          <v-icon left color="success">
            {{ mdiClipboard }}
          </v-icon>
          <span class="success--text"> Copy URL </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="testByUrlEnabled">
          <span class="text-h6"> Test Invite URL Expiry: </span>
          <span v-if="testByUrlEnabled" class="text-h6">
            <span v-if="hasUrlExpiry">
              {{ testUrlExpiry }}
            </span>
            <span v-else> No url expiry set </span>
          </span>
          <v-btn icon @click="editExpiryDate">
            <v-icon>{{ mdiPencil }}</v-icon>
          </v-btn>
        </div>
        <v-btn
          v-else
          color="primary"
          text
          class="text-none text-body-1"
          id="add-test-url"
          @click="editExpiryDate"
        >
          <v-icon left color="primary">
            {{ mdiLink }}
          </v-icon>
          Add Test Url Invite
        </v-btn>
        <v-dialog v-model="dialog" max-width="800px">
          <v-card>
            <v-card-title class="text-h5">Test Settings</v-card-title>
            <v-card-text>
              <v-checkbox
                v-model="urlInviteSelected"
                label="Enable Test Invite by URL"
              ></v-checkbox>
              <v-container v-if="urlInviteSelected" class="mt-4">
                <v-row>
                  <v-col cols="12" md="6">
                    <label for="url-expiry-picker" class="text-h6 black--text"
                      >Set Expiry Date for Test URL</label
                    >
                    <v-date-picker
                      id="url-expiry-picker"
                      :allowed-dates="allowFutureDates"
                      v-model="testUrlExpiryEpoch"
                      elevation="1"
                    ></v-date-picker>
                  </v-col>
                  <v-col cols="12" md="6">
                    <label
                      for="url-expiry-time-picker"
                      class="text-h6 black--text"
                      >Set Expiry Time for Test URL</label
                    >
                    <v-time-picker
                      id="url-expiry-time-picker"
                      color="accent"
                      v-model="expiryTime"
                    ></v-time-picker>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" @click="updateEndDateTime"
                >Save date time</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiClipboard,
  mdiInformationOutline,
  mdiLink,
  mdiPencil,
  mdiDelete,
  mdiCheck,
  mdiClose,
} from '@mdi/js'
import moment from 'moment'
import { formatDate } from '@/utils/helper'
import TimeSelect from '@/views/recruiter/TimeSelect.vue'
import { allowFutureDates } from '@/utils/helper.js'
import { getInviteLink } from '@/utils/whitelabel'

export default {
  data() {
    return {
      mdiClipboard,
      mdiPencil,
      mdiLink,
      mdiInformationOutline,
      mdiCheck,
      mdiDelete,
      mdiClose,
      dialog: false,
      testUrlExpiryEpoch: '',
      expiryTime: null,
      urlInviteSelected: false,
      changeMinScoreDialog: false,
      showMinScoreInput: false,
      showMinScoreInfo: false,
      showInfo: false,
      showDeadlineInfo: false,
      editDeadline: false,
      timeUnits: [
        { label: 'Days', value: 0, multiplier: 86400 },
        { label: 'Hours', value: 0, multiplier: 3600 },
        { label: 'Minutes', value: 0, multiplier: 60 },
        { label: 'Seconds', value: 0, multiplier: 1 },
      ],
    }
  },
  components: {
    TimeSelect,
  },
  props: {},
  computed: {
    ...mapState('recruiter', [
      'assessment',
      'assessmentView',
      'fetchAssessmentStatus',
    ]),
    ...mapGetters('recruiter', ['cheatingPrettyMessage']),
    testStartAt: {
      get() {
        return this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getTestWindow()
          ?.getStartAt()
      },
      set(value) {
        console.log('Setting test start at', value)
        if (this.testEndAt && value > this.testEndAt) {
          this.$store.dispatch('notifs/addNotif', {
            text: 'Start time must be before end time',
            type: 'error',
          })
          return
        }
        this.updatStartWindowTime(value, true)
      },
    },
    testEndAt: {
      get() {
        return this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getTestWindow()
          ?.getEndAt()
      },
      set(value) {
        if (this.testStartAt && value < this.testStartAt) {
          this.$store.dispatch('notifs/addNotif', {
            text: 'End time must be after start time',
            type: 'error',
          })
          return
        }
        console.log('Setting test end at', value)
        this.updatStartWindowTime(value, false)
      },
    },
    minScore: {
      get() {
        return (
          this.assessment
            ?.getMetaData()
            ?.getTestMeta()
            ?.getAutoSelectSetting()
            ?.getMinScore() || 0
        )
      },
      set(value) {
        this.setMinScore(value)
      },
    },
    sendEmail: {
      get() {
        return (
          this.assessment
            ?.getMetaData()
            ?.getTestMeta()
            ?.getAutoSelectSetting()
            ?.getAutoRejectEmail() || false
        )
      },
      set(value) {
        this.setSendEmail(value)
      },
    },
    minScoreRule() {
      return (value) => {
        if (!value) {
          return 'Minimum score is required'
        } else if (value < 0 || value > 100) {
          return 'Minimum score must be between 0 and 100'
        } else {
          return true
        }
      }
    },
    testSkills() {
      if (this.fetchAssessmentStatus && this.assessment) {
        const skillIds = this.assessment
          ?.getDataView()
          ?.getTestView()
          ?.getSkillsList()
        const childPreviews = this.assessmentView?.getChildPreviewsList()
        const skillsText = []
        childPreviews.forEach((p) => {
          if (
            skillIds.includes(p?.getContentPreview()?.getId()) &&
            p?.getContentPreview()?.getContentType() === 7
          ) {
            skillsText.push(p?.getContentPreview()?.getTitle())
          }
        })
        return skillsText
      }
    },
    antiCheats() {
      if (this.fetchAssessmentStatus && this.assessment) {
        const enabledAC = this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getAntiCheatingList()
        const msgs = []
        enabledAC.forEach((cht) => {
          msgs.push(this.cheatingPrettyMessage[cht.getAntiCheatingSettings()])
        })

        return msgs
      }
    },
    testUrl() {
      if (this.fetchAssessmentStatus && this.assessment)
        var customTestUrl = getInviteLink(
          `signup/${this.assessment.getUrl()}/testInvite`,
        )
      console.log('customTestUrl', customTestUrl)
      return customTestUrl
    },
    testByUrlEnabled: function () {
      const value = this.assessment
        ?.getMetaData()
        ?.getTestMeta()
        ?.getTestByUrlEnabled()
      console.log('testByUrlEnabled get', value)
      return value
    },
    testUrlExpiry() {
      if (this.fetchAssessmentStatus && this.assessment) {
        var expiryL = this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getTestUrlExpiry()
        console.log(
          'Expiry is...',
          expiryL,
          this.assessment?.getMetaData()?.getTestMeta(),
        )
        return (
          moment.unix(expiryL).format('YYYY-MM-DD') +
          ' ' +
          moment.unix(expiryL).format('HH:mm')
        )
      }
    },
    hasUrlExpiry() {
      return (
        this.assessment?.getMetaData()?.getTestMeta()?.getTestUrlExpiry() !== 0
      )
    },
    hasDeadline() {
      const hasDeadline =
        this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getCandidateDeadline() !== undefined &&
        this.assessment
          .getMetaData()
          .getTestMeta()
          .getCandidateDeadline()
          ?.getCandidateDeadlineInSeconds() !== 0
      return hasDeadline
    },
  },
  methods: {
    ...mapMutations('recruiter', [
      'setExpiry',
      'setTestUrlEnabled',
      'setMinScore',
      'setSendEmail',
      'setTestStartAt',
      'setTestEndAt',
      'setTestDeadline',
    ]),
    ...mapActions('recruiter', ['updateMetaData', 'fetchAssessment']),
    formatDate: formatDate,
    allowFutureDates: allowFutureDates,

    updateDeadline() {
      const testDeadlineValue = this.timeUnits.reduce(
        (acc, unit) => acc + unit.value * unit.multiplier,
        0,
      )
      console.log('Deadline value ..', testDeadlineValue)
      if (testDeadlineValue >= 0) {
        // Update the deadline value
        console.log('Deadline value ..', testDeadlineValue)
        this.setTestDeadline({ deadlineInSeconds: testDeadlineValue })
        console.log('Deadline value', testDeadlineValue)
        this.updateMetaData({ url: this.assessment.getUrl() })
          .then((res) => {
            console.log('Updated deadline', res)
            this.extractCandidateDeadline()
            console.log('unit value', this.timeUnits)
            this.editDeadline = false
            this.$store.dispatch('notifs/addNotif', {
              text: 'Updated Test Deadline',
              type: 'success',
            })
          })
          .catch((err) => {
            console.log('Error updating deadline', err)
            this.$store.dispatch('notifs/addNotif', {
              text: 'Error updating test deadline',
              type: 'error',
            })
          })
      } else {
        this.$store.dispatch('notifs/addNotif', {
          text: 'Deadline value must not be negative',
          type: 'error',
        })
      }
    },
    removeDeadline() {
      this.timeUnits.forEach((unit) => {
        this.$set(unit, 'value', 0)
      })
      this.updateDeadline()
    },
    cancelDeadlineEdit() {
      this.editDeadline = false
      this.extractCandidateDeadline()
    },
    openStartTime() {
      this.$refs.startTime.openDialog()
    },
    openEndTime() {
      this.$refs.endTime.openDialog()
    },
    changeMinScore() {
      console.log('New min score', this.minScore)
      if (this.minScore !== null) {
        this.updateMetaData({ url: this.assessment.getUrl() })
          .then((res) => {
            return this.fetchAssessment({ url: this.assessment.getUrl() })
          })
          .then((res) => {
            console.log('Updated minimum score', res)
            this.refreshMetadata()
            this.$store.dispatch('notifs/addNotif', {
              text: 'Updated Minimum Score',
              type: 'success',
            })
            this.dialog = false
          })
          .catch((err) => {
            console.log('Error updating minimum Score', err)
            this.$store.dispatch('notifs/addNotif', {
              text: 'Error updating Min Score',
              type: 'error',
            })
          })
        this.showMinScoreInput = false
      }
    },
    async copyTestUrlToClipboard() {
      try {
        await navigator.clipboard.writeText(this.testUrl)
        this.$store.dispatch('notifs/addNotif', {
          text: `Copied URL to clipboard!`,
          type: 'success',
        })
      } catch (err) {
        this.$store.dispatch('notifs/addNotif', {
          text: `Erropr copying URL, try again!`,
          type: 'error',
        })
      }
    },
    async updatStartWindowTime(value, isStart) {
      if (isStart) this.setTestStartAt(value)
      else this.setTestEndAt(value)
      this.updateMetaData({ url: this.assessment.getUrl() })
        .then((res) => {
          return this.fetchAssessment({ url: this.assessment.getUrl() })
        })
        .then((res) => {
          this.refreshMetadata()
          console.log('Updated expiry', res)
          this.$store.dispatch('notifs/addNotif', {
            text: 'Updated Test Start date and time',
            type: 'success',
          })
          this.dialog = false
        })
        .catch((err) => {
          console.log('Error updating expiry', err)
          this.$store.dispatch('notifs/addNotif', {
            text: 'Error updating test start date and time',
            type: 'error',
          })
        })
    },
    async updateEndDateTime() {
      var epoch = this.calculateDatetime(
        this.testUrlExpiryEpoch,
        this.expiryTime,
      )
      console.log('End epoch ', epoch)
      this.setExpiry(epoch)
      this.setTestUrlEnabled(this.urlInviteSelected)
      this.updateMetaData({ url: this.assessment.getUrl() })
        .then((res) => {
          return this.fetchAssessment({ url: this.assessment.getUrl() })
        })
        .then((res) => {
          this.refreshMetadata()
          console.log('Updated expiry', res)
          this.$store.dispatch('notifs/addNotif', {
            text: 'Updated expiry date',
            type: 'success',
          })
          this.dialog = false
        })
        .catch((err) => {
          console.log('Error updating expiry', err)
          this.$store.dispatch('notifs/addNotif', {
            text: 'Error updating expiry date',
            type: 'error',
          })
        })
    },
    calculateDatetime(date, time) {
      if (time && date) {
        var datetime = date + ' ' + time
        return moment(datetime, 'YYYY-MM-DD H:mm').unix()
      }
    },
    editExpiryDate() {
      this.dialog = true
      if (!this.hasUrlExpiry) {
        this.testUrlExpiryEpoch = moment().add(7, 'days').format('YYYY-MM-DD')
        this.expiryTime = moment().format('HH:mm')
      }
    },
    extractDateTime() {
      this.urlInviteSelected = this.assessment
        ?.getMetaData()
        ?.getTestMeta()
        ?.getTestByUrlEnabled()
      var expiryL =
        this.assessment?.getMetaData()?.getTestMeta()?.getTestUrlExpiry() / 1000
      console.log(
        'Expiry is...',
        expiryL,
        this.assessment?.getMetaData()?.getTestMeta(),
      )
      this.testUrlExpiryEpoch = moment.unix(expiryL).format('YYYY-MM-DD')
      this.expiryTime = moment.unix(expiryL).format('HH:mm')
    },
    extractCandidateDeadline() {
      const totalSeconds =
        this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getCandidateDeadline()
          ?.getCandidateDeadlineInSeconds() || 0

      let remainingSeconds = totalSeconds

      this.timeUnits.forEach((unit) => {
        const deadline = Math.floor(remainingSeconds / unit.multiplier)
        this.$set(unit, 'value', deadline)
        remainingSeconds %= unit.multiplier

        console.log(
          `Unit: ${unit.label}, Deadline: ${deadline}, Remaining Seconds: ${remainingSeconds}`,
        )
      })
    },
    refreshMetadata() {
      this.extractAll()
    },
    extractAll() {
      this.extractDateTime()
      this.minScore =
        this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getAutoSelectSetting()
          ?.getMinScore() || 0
      this.extractCandidateDeadline()
    },
  },
  watch: {
    assessment: function () {
      this.extractAll()
    },
  },
  mounted() {
    this.extractAll()
  },
}
</script>
